import { Form } from "react-final-form";
import s from "./otp.module.scss";
import Button from "@/components/atoms/Button";
import React, { useCallback } from "react";
import OTPInput from "@/components/atoms/Form/OTPInput";
import OTPInput6 from "@/components/atoms/Form/OTPInput6";
import { useState, useEffect } from "react";
import cn from "clsx";
import { useOTPContext } from "@/components/sections/CommonModals/context";

const COUNTER_SECONDS = 180;
const VerifyOTPForm = () => {
  const {
    message,
    isOTPLoading,
    setOTPSent,
    handleSendOTP,
    handleVerifyOTP,
    otpResponse,
    formCallback,
    setMessage
  } = useOTPContext();
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(COUNTER_SECONDS);

  const resetTimer = () => {
    setResendDisabled(true);
    setCountdown(COUNTER_SECONDS);

    // Start the countdown when the component mounts
    const countdownId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          // Clear the interval if the countdown is over
          clearInterval(countdownId);
          return 0;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    // Stop the countdown after 45 seconds
    const timeoutId = setTimeout(() => {
      clearInterval(countdownId);
      setResendDisabled(false);
    }, COUNTER_SECONDS * 1000);

    // Clear the interval and timeout if the component unmounts
    return () => {
      clearInterval(countdownId);
      clearTimeout(timeoutId);
    };
  };
  
  useEffect(resetTimer, []);

  const handleSubmit = useCallback(
    (e, form) => {
      handleVerifyOTP(e);
      form?.reset();
    },
    [otpResponse]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: any = {};
        if (!/\d{6}/.test(values.otp)) {
          errors.email = "Please enter OTP";
        }

        return errors;
      }}
    >
      {({ form, handleSubmit, hasValidationErrors }) => {
        const state = form.getState();
        return (
          <form
            className={`${s.content} py-4 flex flex-col`}
            onSubmit={handleSubmit}
          >
            {message === null && 
              <div
              className={`text-blu font-baskervville font-small capitalize mb-3 lg:mb-4 ${s.header}`}
              >
                OTP has been sent successfully
              </div>
            }
            {message ? <div className="text-red-800">{message}</div> : null}
            <div
              className={`flex w-min flex-wrap items-center justify-between`}
              style={{ width: "100%" }}
            >
              <span className="text-xs text-blu underline">
                Enter OTP sent to {otpResponse.mobile}
              </span>
              <span
                className="text-xs flex items-center whitespace-nowrap cursor-pointer"
                onClick={(e) => {
                  setOTPSent(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  className="ml-auto"
                >
                  <g clipPath="url(#clip0_1165_4804)">
                    <path
                      d="M12.3025 7.8925L13.1075 8.6975L5.18 16.625H4.375V15.82L12.3025 7.8925ZM15.4525 2.625C15.2337 2.625 15.0062 2.7125 14.84 2.87875L13.2388 4.48L16.52 7.76125L18.1212 6.16C18.4625 5.81875 18.4625 5.2675 18.1212 4.92625L16.0738 2.87875C15.8988 2.70375 15.68 2.625 15.4525 2.625ZM12.3025 5.41625L2.625 15.0938V18.375H5.90625L15.5837 8.6975L12.3025 5.41625Z"
                      fill="#1A325D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1165_4804">
                      <rect width="21" height="21" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span>Edit Number</span>
              </span>
              <div className="w-100 mt-4" style={{ width: "100%" }}>
                <OTPInput6
                  name="otp"
                  message={message}
                  key={isOTPLoading && message}
                  loading={isOTPLoading}
                />
              </div>
            </div>
            <div className="mb-2"></div>
            <div
              className={`${cn({
                ["grey"]: resendDisabled,
                ["text-blu underline"]: !resendDisabled,
              })} mb-4 text-xs inline-flex w-auto`}
            >
              <span
                onClick={() => {
                  if (!resendDisabled) {
                    setMessage(null);
                    handleSendOTP(otpResponse, formCallback?.cb);
                    resetTimer();
                    setResendDisabled(true);
                  }
                }}
                className={`${cn({
                  ["cursor-pointer"]: !resendDisabled,
                })}`}
              >
                Resend OTP
              </span>{" "}
              {resendDisabled && (
                <span style={{ marginLeft: 4 }}>
                in {Math.floor(countdown / 60).toString().padStart(2, "0")} min{" "}
                 {(countdown % 60).toString().padStart(2, "0")} sec
              </span>
              )}
            </div>
            <Button
              id="btnVerifySuccess"
              type="submit"
              disabled={isOTPLoading || hasValidationErrors}
            >
              VERIFY{isOTPLoading ? "ING" : ""} OTP
            </Button>
          </form>
        );
      }}
    </Form>
  );
};

export default VerifyOTPForm;
