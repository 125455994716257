import Loader from "@/components/atoms/Loader/Loader";
import { StorageService } from "@/helpers/storage.service";
import VerifyOTPForm from "@molecules/Forms/VerifyOTPForm";
import { usePathname, useRouter } from "next/navigation";
import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from "react";

export const OTPContext = createContext<any>({});

export function OTPContextProvider({ children }) {
  const [formCallback, setFormCallback] = useState(null);
  const [otpGaCb, setVerifyOtpGaCb] = useState(null);
  const [isOTPSent, setOTPSent] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoading, loading] = useState(false);
  const [leadResponse, setLeadResponse] = useState(null);
  const [otpMessage, setMessage] = useState(null);
  const [otpResponse, setResponse] = useState(null);
  const [otpVerified, setIsOtpVerified] = useState(false);
  const [isInternationalNumber, setIsInternationalNumber] = useState(false);

  const pathname = usePathname();

  const handleSendOTP = async (
    { phone, mobile, phoneNumber, countryCode, emailId, email, ...rest },
    cb: any, submitGaCb:()=>void, verifyOtpGaCb : any
  ) => {
    const mobilePhone = phone || mobile || phoneNumber || emailId || email;

    if(typeof cb === 'function'){
      await cb();
    }

    if(countryCode != "+91"){
      setIsInternationalNumber(true);
      return
    }

    setFormCallback({ cb });
    setVerifyOtpGaCb({ verifyOtpGaCb });
    loading(true);

  fetch("/api/otp/send", {
    method: "post",
    body: JSON.stringify({ mobile: mobilePhone }),
  })
    .then((d) => d.json())
    .then((d) => {
      loading(false);
      if (d.success) {
        setOTPSent(true);
        setMessage(null);
        delete d.otp;
        setResponse({
          ...d,
          mobile: mobilePhone,
          countryCode: countryCode,
        });
        if(typeof submitGaCb === "function"){
          submitGaCb();
        }
      } else {
        setMessage(d.message);
      }
    })
    .catch((e) => {})
    .finally(() => {
      loading(false);
    });
  }

 const leadUpdateApiCall = async (mFilterData = {}) =>{ 
    const res = fetch("/api/leadsquared.com/lead-update", {
        method: "POST",
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        form : { "leadId" : leadResponse?.ProspectId || "", "Custom5" : "Verified", ...(Object.keys(mFilterData).length && {...mFilterData}) },
      }),
    });
    res.then((e) => e.json())
    .then((e)=> {
      if(e.success){
        const response = e?.response || {};
        if(response?.Status === "Success"){
          setOTPSent(false);
          setIsOtpVerified(true);
          setMessage(null);
          setShowLoader(true);
          otpGaCb?.verifyOtpGaCb && otpGaCb.verifyOtpGaCb();
        }else if(response?.Status==="Error"){
          setMessage("We are encountering the server issue please try again.");
          console.error("Error to update lead");
        }
      }
    })
 }

  const handleVerifyOTP = useCallback(
    (body: { otp: string } ) => {

      const payload = {
        hash: otpResponse.hash,
        mobile: otpResponse.mobile,
        otp: body.otp,
      };
      
      loading(true);
      fetch("/api/otp/verify", {
        method: "post",
        body: JSON.stringify(payload),
      })
      .then((d) => d.json())
      .then((d) => {
        // by pass otp verification if mobile number starts with 23
        if(otpResponse.mobile && otpResponse.mobile.startsWith("23") && !(pathname.includes('/corporates') || pathname.includes('/ip-listing') || pathname.includes('/contact-us'))){
          leadUpdateApiCall();
          return;
        }
        if((pathname.includes('/corporates') || pathname.includes('/ip-listing') || pathname.includes('/contact-us')) && d.success){
          setOTPSent(false);
          setIsOtpVerified(true);
          setMessage(null);
          setShowLoader(true);
          otpGaCb?.verifyOtpGaCb && otpGaCb.verifyOtpGaCb();
        } 
        if(pathname.includes('/lp-nw') && d.success){
          const data = StorageService.get('mFitlerKeyDetails') || null
          if(!data){
            leadUpdateApiCall();
          }else {
            const { mf_call_recommendation, mf_media_risk_level, mf_token:Mf_token, mf_trans_id }  = data.botData
            leadUpdateApiCall({ mf_call_recommendation, mf_media_risk_level, Mf_token, mf_trans_id });
          }
        }
        else if (d.success) {
          leadUpdateApiCall();
        } else {
          setMessage(d.message);
        }
      })
      .catch((e) => {})
      .finally(() => {
        loading(false);
      });
    },
    [formCallback, otpResponse, otpResponse?.hash]
  );

  const value = useMemo(() => {
    return {
      isOTPLoading: isLoading,
      isOTPSent,
      showLoader,
      message: otpMessage,
      formCallback,
      otpGaCb,
      otpResponse,
      setOTPSent,
      setShowLoader,
      setMessage,
      handleSendOTP,
      handleVerifyOTP,
      setLeadResponse,
      otpVerified,
      isInternationalNumber,
      leadResponse
    };
  }, [
    isLoading,
    isOTPSent,
    showLoader,
    otpMessage,
    formCallback,
    otpGaCb,
    otpResponse,
    setOTPSent,
    setShowLoader,
    setMessage,
    handleSendOTP,
    handleVerifyOTP,
    setLeadResponse,
    otpVerified,
    isInternationalNumber,
    leadResponse
  ]);

  return <OTPContext.Provider value={value}>{children}</OTPContext.Provider>;
}

export const useOTPContext = () => useContext(OTPContext);

export const OTPContextWrapper = ({ children }) => {
  const { isOTPSent, setOTPSent, setMessage, showLoader, setShowLoader} =
    useOTPContext();

  useEffect(() => {
    return () => {
      setOTPSent(false);
      setShowLoader(false);
      setMessage(null);
    };
  }, []);

  return (
    <>
      <div className={`${isOTPSent || showLoader? "hidden" : ""}`}>
        {children}
      </div>
      {isOTPSent && <VerifyOTPForm />}
      {showLoader && <Loader />}
    </>
  );
};
